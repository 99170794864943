import React from 'react';

export interface RotatorDotProps
  extends Pick<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'onClick' | 'disabled'
  > {
  'aria-label': string;
  active?: boolean;
  edge?: boolean;
}

export const RotatorDot: React.FC<RotatorDotProps> = ({
  active = false,
  edge = false,
  'aria-label': ariaLabel,
  ...props
}) => (
  <button
    aria-label={ariaLabel}
    className={`h-[10px] w-[10px] rounded-full bg-neutral-300 bg-cover bg-center bg-no-repeat ${
      active ? 'rounded-full bg-neutral-800' : ''
    } ${edge ? 'h-s w-s' : ''}`}
    type="button"
    {...props}
  ></button>
);
