import React from 'react';
import { ScComponent } from '@/types/interfaces';
import { RotatorDot } from '@/components/atoms/RotatorDot/RotatorDot';

export interface RotatorDotProps extends ScComponent {
  size?: number;
  selected: number;
  imagesLength: number;
}

const RotatorDotGroup: React.FC<RotatorDotProps> = ({
  className = '',
  size = 5,
  selected,
  imagesLength,
}) => {
  // Right now we only show 5 dots at a time if there are more than 5 images
  const getActiveIndex = (selectedIndex: number, totalImageSize: number) => {
    if (selectedIndex === 0) return 0;
    if (selectedIndex === 1) return 1;
    if (selectedIndex === totalImageSize - 2) return 3;
    if (selectedIndex === totalImageSize - 1) return 4;
    return 2;
  };
  const activeIndexes = getActiveIndex(selected, imagesLength);

  return (
    <section
      className={`rotator-dot-group flex items-center space-x-[3px] ${className}`}
    >
      {[...Array(size)].map((_, i) => (
        <RotatorDot
          aria-label={`dot ${i + 1}`}
          key={i}
          edge={size > 2 && (i === 0 || i === size - 1)}
          active={activeIndexes === i}
        />
      ))}
    </section>
  );
};

export default RotatorDotGroup;
