import { TextButtonVariants } from '@/components/atoms/TextButton/TextButton';
import TextButtonLink from '@/components/atoms/TextButtonLink/TextButtonLink';
import Carousel from '@/components/molecules/Carousel';
import { EVSavingsToggle } from '@/components/molecules/EVSavingsToggle/EVSavingsToggle';
import { useZipContext } from '@/context/ZipContext';
import useWindowSize from '@/hooks/useWindowSize';
import { parseListingVehicle } from '@/lib/parseListingVehicle';
import { VehicleListingModel } from '@/lib/schema/inventory/types';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import { ProductListingLink } from '../ProductListingLink/ProductListingLink';

export interface PopularMakesCarouselProps {
  estFuelSavingsByType: Record<string, { savings: number }> | null;
  sectionHeader: string;
}

export const PopularMakesCarousel: FC<PopularMakesCarouselProps> = ({
  estFuelSavingsByType,
  sectionHeader,
}) => {
  const { userAddress, customZip } = useZipContext();

  const fetchData = useCallback(async () => {
    let zip;
    if (customZip) {
      zip = customZip;
    } else if (userAddress && userAddress.country === 'US') {
      zip = userAddress.postal;
    }

    try {
      const response = await fetch('/api/inventory/listings/popular', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postal: zip,
          limit: 20,
          radius_km: 322,
          sort: 'rankedscore_desc',
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const { content: matchesRaw }: { content: VehicleListingModel[] } =
          data;
        const matches = matchesRaw.map((product: VehicleListingModel) =>
          parseListingVehicle(product)
        );

        return matches;
      }
    } catch (error) {
      return [];
    }
    return [];
  }, [customZip, userAddress]);

  const { data: vehicleList = [] } = useQuery({
    queryKey: ['fetchPopularListing', userAddress, customZip],
    queryFn: fetchData,
    throwOnError: false,
    refetchOnWindowFocus: false,
  });

  const { isDesktop, isML, isM, isMobile } = useWindowSize();
  const { imagesLength } = useMemo(() => {
    if (isDesktop) {
      return {
        imagesLength: vehicleList.slice(0, 5).length,
      };
    }
    if (isML) {
      return {
        imagesLength: Math.ceil(vehicleList.length / 3),
      };
    }
    if (isM) {
      return {
        imagesLength: Math.ceil(vehicleList.length / 2),
      };
    }
    return { imagesLength: vehicleList.length };
  }, [vehicleList, isDesktop, isML, isM]);

  if (vehicleList.length > 0) {
    return (
      <div className="flex flex-col pt-[40px]">
        <div className="mb-xl flex flex-col items-center justify-between gap-m m:flex-row">
          <div className="self-start text-h3Regular text-neutral-900">
            {sectionHeader}
          </div>
          <div className="flex w-full items-center justify-between gap-[40px] m:w-auto">
            <div className="flex justify-between gap-s">
              <div className="text-body1Light text-neutral-900">EV Savings</div>
              <EVSavingsToggle size={'small'} />
            </div>
            <TextButtonLink
              aria-label="View More Evs"
              variant={TextButtonVariants.Default}
              href="/search"
              icon={{
                position: 'right',
                icon: <ChevronRightIcon className="h-xl w-xl" />,
              }}
            >
              View all
            </TextButtonLink>
          </div>
        </div>
        <div>
          <Carousel
            className="w-full gap-l"
            showDirectionButtons={true}
            navigationPosition="bottom"
            imagesLength={imagesLength}
            infinite={true}
            isShowingDots={true}
          >
            {vehicleList.map((vehicle) => {
              return (
                <div
                  key={`popular-make-carousel-${vehicle.listingId}`}
                  className={`w-full pr-m m:w-1/2 ml:w-1/3 l:w-[25%]`}
                >
                  <ProductListingLink
                    showNewCheckAvailabilityButton={true}
                    product={vehicle}
                    isSaved={false}
                    isColumnLayout={true}
                    isListedPrice={true}
                    isMobile={isMobile}
                    contactedDealer={false}
                    estFuelSavingsByType={estFuelSavingsByType}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  }
  return null;
};
